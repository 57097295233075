import * as React from 'react'
import Finger from 'ainojs-finger'
import isNode from 'is-node'
import { autorun } from 'mobx'
import { inject, observer } from 'mobx-react'

import styles from './Carousel.module.scss'

type Props = {
  children: any
  ui?: any
}

@inject('ui')
@observer
class Carousel extends React.Component<Props> {
  finger: any
  container: any
  slides: any
  controls: any
  disposer: any

  loopFinger = () => {
    this.finger.run()
    window.requestAnimationFrame(this.loopFinger)
  }

  setSlidesPosition = () => {
    const { ui } = this.props
    let index = 0
    if (this.slides && ui && ui.windowWidth) {
      for (let slide of this.slides.children) {
        slide.style.left = `${index * ui.windowWidth}px`
        index++
      }
    }
  }

  setActiveControl = (index: number) => {
    let count = 0
    if (this.controls) {
      for (let control of this.controls.children) {
        control.style.background = index === count ? '#ffffff' : 'transparent'
        count++
      }
    }
  }

  goToSlide = (index: number) => {
    if (this.finger) {
      this.finger.animateTo(index)
    }
  }

  componentDidMount() {
    const { ui } = this.props
    this.disposer = autorun(() => {
      if (!isNode) {
        if (ui && ui.windowWidth) {
          this.setSlidesPosition()
        }
      }
    })

    const options = {
      items: this.slides.childElementCount,
    }

    this.setSlidesPosition()
    this.finger = Finger(this.container, options)
    this.loopFinger()
    const that = this

    this.finger.on('change', function(e: any) {
      this.inner.style.left = e.position + 'px'
    })
    this.finger.on('complete', function(e: any) {
      that.setActiveControl(e.index)
    })
  }

  componentWillUnmount() {
    this.finger.destroy()
  }

  render() {
    const { children } = this.props

    return (
      <div ref={x => (this.container = x)} className={styles.container}>
        <div ref={x => (this.slides = x)} className={styles.slides}>
          {children}
        </div>
        <div ref={x => (this.controls = x)} className={styles.controls}>
          {children.map((el: any, index: number) => (
            <div onClick={() => this.goToSlide(index)} key={index} />
          ))}
        </div>
      </div>
    )
  }
}

export default Carousel

import * as React from 'react'
import { Link } from 'gatsby'

import styles from './Breadcrumbs.module.scss'

interface Props {
  items: object[]
}

const BreadCrumbs = ({ items }: Props) => (
  <div className={styles.breadcrumbs}>
    {items.map((item: any, index: number) => (
      <React.Fragment key={index}>
        {item.link ? (
          <span>
            <Link to={item.link}>{item.name}</Link>
          </span>
        ) : (
          <span>{item.name}</span>
        )}
        {index !== items.length - 1 && (
          <div className={styles.separator}>/</div>
        )}
      </React.Fragment>
    ))}
  </div>
)

export default BreadCrumbs

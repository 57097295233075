import * as React from 'react'
import { autorun } from 'mobx'
import { inject, observer } from 'mobx-react'

import productinfo from 'src/json/productinfo.json'
import AddToCart from 'src/components/Product/AddToCart/AddToCart'
import BreadCrumbs from 'src/components/Breadcrumbs/Breadcrumbs'
import Carousel from 'src/components/UI/Carousel/Carousel'
import Image from 'src/components/UI/Image/Image'
import Price from 'src/components/Product/Price/Price'
import ProductGrid from 'src/components/ProductGrid/ProductGrid'
import TabList from 'src/components/Product/TabList/TabList'
import VariantSelect from 'src/components/Product/VariantSelect/VariantSelect'
import styles from 'src/components/Product/Product.module.scss'
import { Above, Below } from 'src/components/UI/Breakpoints'
import {
  getPrice,
  isUnavailable,
  isItemAvailable,
  getProductComponentData,
} from 'src/utils/product'
import { fetchCentraData } from 'src/utils/api'
import { getCurrentSiteData } from 'src/helpers/getCurrentSiteData'
import SEO from 'src/components/SEO/SEO'
import DeliveryInfo from 'src/components/Product/DeliveryInfo/DeliveryInfo'
import ProductSEO from 'src/components/Product/ProductSEO/ProductSEO'

type Props = {
  cart: any
  pageContext: any
  preview: any
  ui: any
}

type State = {
  activeVariant: any
  fetched: boolean
  items: any
  unavailable: boolean | null
}

@inject('cart', 'preview', 'ui')
@observer
export default class Product extends React.Component<Props, State> {
  disposer: any

  state = {
    activeVariant: {},
    unavailable: null,
    items: null,
    fetched: false,
  }

  constructor(props: Props) {
    super(props)
    const { product } = props.pageContext

    // Check stock here, set first buyable variant
    this.state.activeVariant = product && product.items ? product.items[0] : {}
    this.state.items = product.items
  }

  componentDidMount() {
    // we dont know about availability until we have a country
    const { cart } = this.props
    const { product } = this.props.pageContext
    this.fetchProduct()
    this.disposer = autorun(() => {
      if (cart.country) {
        this.setState({
          unavailable: isUnavailable(product.markets, cart.country),
        })
      }
    })
  }

  fetchProduct = async () => {
    const { cart } = this.props
    const { product } = this.props.pageContext
    const response = await fetchCentraData(
      `products/${product.product}`
    )

    if (response && response.product) {
      const fetchedProduct = response.product

      if (fetchedProduct) {
        const items = fetchedProduct.items
        const validItems = items.filter((item: any) =>
          isItemAvailable(cart.country, item.stock)
        )

        this.setState({
          items: items,
          activeVariant: validItems[0],
          fetched: true,
        })
      }
    }
  }

  setActiveVariant = (variant: any): void => {
    const key = variant.value
    const activeVariant = this.state.items.find((x: any) => x.item === key)

    this.setState({
      activeVariant: activeVariant,
    })
  }

  render(): JSX.Element {
    const { cart, pageContext, preview } = this.props
    const productInfo = getCurrentSiteData(productinfo)

    let { product } = pageContext
    if (preview.previewProducts) {
      product = preview.getCurrentProductContext(product.product)
    }
    const { activeVariant } = this.state
    const { excerpt, name, media, description } = product
    const { items, fetched } = this.state
    const ogImage = media[0] ? media[0].sources.standard.url : null

    const { tabInfo, related, bredcrumbsItems } = getProductComponentData(
      product,
      productInfo
    )

    const inStock = activeVariant ? isItemAvailable(cart.country, activeVariant.stock) : false
    
    return product ? (
      <React.Fragment>
        <SEO
          seoDescription={description}
          seoOgimage={ogImage}
          type="product"
          seoTitle={name}
        />
        <ProductSEO product={product} />
        <div className="container-desktop">
          <div className="container-mobile">
            <BreadCrumbs items={bredcrumbsItems} />
          </div>
          <section className={styles.productWrapper}>
            <div className={styles.imageSection}>
              <Above breakpoint="mobile">
                <React.Fragment>
                  {media.map(
                    (image: any, index: number): JSX.Element => (
                      <Image
                        className={styles.image}
                        key={index}
                        src={image.sources.full.url}
                        alt={name}
                      />
                    )
                  )}
                </React.Fragment>
              </Above>
              <Below breakpoint="mobile">
                <Carousel>
                  {media.map(
                    (image: any, index: number): JSX.Element => (
                      <div key={index}>
                        <Image
                          src={image.sources.full.url}
                          alt={name}
                        />
                      </div>
                    )
                  )}
                </Carousel>
              </Below>
            </div>
            <div className={styles.productSection}>
              <div className={styles.sticky}>
                <div>
                  <h1>{name}</h1>
                  <span className={styles.sku}>{product.productSku}</span>
                  <Price price={getPrice(product.markets, cart.country)} />

                  {items && items.length > 1 && (
                    <VariantSelect
                      key={fetched ? 1 : 2}
                      items={items.map(item => ({
                        ...item,
                        disabled: !isItemAvailable(
                          shop.country,
                          item.stock
                        ),
                      }))}
                      selected={activeVariant}
                      onChange={this.setActiveVariant}
                    />
                  )}
                  <AddToCart
                    disabled={
                      !isItemAvailable(
                        cart.country,
                        activeVariant ? activeVariant.stock : {}
                      )
                    }
                    variant={activeVariant}
                  />
                </div>
                <DeliveryInfo inStock={inStock} />
                {tabInfo && <TabList items={tabInfo} />}
              </div>
            </div>
          </section>
        </div>
        {related && (
          <div className={styles.related}>
            <div className="container">
              <h4>More items</h4>
            </div>
            <ProductGrid fluidStyle={true} products={related} />
          </div>
        )}
      </React.Fragment>
    ) : (
        <div />
      )
  }
}

import * as React from 'react'
import Helmet from 'react-helmet'
import { t as typy } from 'typy'

interface Props {
  product: any
}

const ProductSEO = ({ product }: Props) => {
  const image = typy(product, 'media[0].sources.standard.url').safeObject
  const object = {
    '@context': 'http://schema.org/',
    '@type': 'Product',
    name: product.name,
    image,
    description: product.description,
    sku: product.productSku,
    productID: product.productSku,
    brand: {
      '@type': 'Thing',
      name: product.brandName,
    },
  }

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(object, null, 2)}
      </script>
    </Helmet>
  )
}

export default ProductSEO

import * as React from 'react'
import { FaShippingFast, FaCheck } from "react-icons/fa";

import styles from './DeliveryInfo.module.scss'

type Props = {
  inStock: boolean
}

const DeliveryInfo = ({ inStock }: Props) => {
  return (
    <div className={styles.wrapper}>
      {inStock && (
        <div className={styles.row}>
          <FaCheck />
          <p>In stock</p>
        </div>
      )}
      <div className={styles.row}>
        <FaShippingFast />
        <p>Fast shipping</p>
      </div>
    </div>
  )
}

export default DeliveryInfo

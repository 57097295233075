import * as React from 'react'
import SelectComponent from 'react-select'

type Props = {
  options: any
  onChange?: any
  selectedOption?: object
  selectStyles?: object
}

class Select extends React.Component<Props> {
  public state = {
    selectedOption: null,
  }

  constructor(props: any) {
    super(props)
    if (props.selectedOption) {
      this.state = {
        selectedOption: props.selectedOption,
      }
    }
  }

  handleChange = (selectedOption: object): void => {
    this.setState({ selectedOption })
    if (this.props.onChange) {
      this.props.onChange(selectedOption)
    }
    return
  }

  render(): JSX.Element {
    const { selectedOption } = this.state
    const { options, selectStyles } = this.props

    return (
      <SelectComponent
        value={selectedOption}
        styles={selectStyles}
        onChange={this.handleChange}
        options={options}
        isOptionDisabled={(option: any) => option.disabled === 'yes'}
      />
    )
  }
}

export default Select

import * as React from 'react'

import PrimaryButton from 'src/components/UI/Button/PrimaryButton'
import { SpinnerIcon, CheckIcon } from 'src/components/UI/Icon/Icon'

import styles from './AddToCart.module.scss'
import { inject, observer } from 'mobx-react'

type Props = {
  variant: any
  cart?: any
  disabled?: boolean
}

interface IButtonState extends Partial<Event> { loading: boolean; success: boolean; }

@inject('cart')
@observer
class AddToCart extends React.Component<Props> {
  state = {
    loading: false,
    success: false,
  }

  updateButtonState = ({ loading, success }: IButtonState) => {
    this.setState({ loading, success }, () => {
      if (success) {
        setTimeout(() => {
          this.setState({ success: false })
        }, 2000)
      }
    })
  }


  handleClick = async (e: any) => {
    e.stopPropagation()
    e.preventDefault()
    try {
      const { cart, variant } = this.props
      this.setState({ loading: true })
      await cart.addItem(variant.item)

      this.setState({ loading: false, success: true })
      this.timer = setTimeout(() => {
        this.setState({ success: false })
      }, 2000)
    } catch (error) {
      // const { ui } = this.props
      this.setState({ loading: false })
      // ui?.setErrorMessage(error.message)
      console.log('ERROR ADD TO CART', error)
    }
  }

  render(): JSX.Element {
    const { disabled } = this.props
    const { loading, success } = this.state

    return (
      <div>
        {disabled ? (
          <PrimaryButton disabled>{'Out of stock'}</PrimaryButton>
        ) : (
            <React.Fragment>
              <PrimaryButton onClick={this.handleClick}>
                {loading ? (
                  <SpinnerIcon width={24} height={24} color="white" />
                ) : success ? (
                  <CheckIcon
                    styles={styles.checkmark}
                    height={36}
                    width={36}
                    color="white"
                  />
                ) : (
                      'Add to bag'
                    )}
              </PrimaryButton>
            </React.Fragment>
          )}
      </div>
    )
  }
}

export default AddToCart

import * as React from 'react'

import Select from 'src/components/UI/Select/Select'

import selectStyles from './selectStyles'

type ItemProps = {
  name: string
  item: string
}

const VariantSelect = ({ items, selected, onChange }: any): JSX.Element => {
  const options = items.map(({ name, item, disabled }: ItemProps): object => {
    // Add stock check here to set disable if out of stock
    return {
      value: item,
      label: name,
      disabled: disabled ? 'yes' : 'no',
    }
  })

  const selectedOption = {
    value: selected.item,
    label: selected.name,
  }

  return (
    <Select
      onChange={onChange}
      options={options}
      selectedOption={selectedOption}
      selectStyles={selectStyles}
    />
  )
}

export default VariantSelect

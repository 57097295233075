import * as React from 'react'
import Media from 'react-media'

type Props = {
  breakpoint: string
  children: JSX.Element
}

const labels: string[] = ['xs', 's', 'mobile', 'desktop', 'l', 'xl']
const breakpointIndex: number = labels.indexOf('mobile')
const breakpoints: string[] = [
  '374px',
  '764px',
  '1023px',
  '1024px',
  '1099px',
  '1499px',
]

const getBreakPoint = (breakpoint: string) => {
  const index = labels.indexOf(breakpoint)
  return breakpoints[index]
}

const Above = ({ breakpoint, children }: Props) => (
  <Media
    query={'(min-width:' + getBreakPoint(breakpoint) + ')'}
    defaultMatches={labels.indexOf(breakpoint) > breakpointIndex}
  >
    {children}
  </Media>
)
const Below = ({ breakpoint, children }: Props) => (
  <Media
    query={'(max-width:' + getBreakPoint(breakpoint) + ')'}
    defaultMatches={false}
  >
    {children}
  </Media>
)

export { Above, Below }

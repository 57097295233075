const selectStyles: object = {
  control: (provided: any, state: any): object => {
    return {
      ...provided,
      height: 44,
      marginBottom: 8,
      border: '1px solid #000000',
      boxShadow: state.menuIsOpen ? '0 0 0 2px rgba(0,0,0,0.10)' : 'none',
      color: '#6E6E6E',
      borderColor: '#000000',
      fontFamily: 'Inter',
      fontSize: '14px',
      '&:hover': {
        color: '#000000',
        borderColor: '#000000',
      },
      cursor: 'pointer',
      borderBottomLeftRadius: state.menuIsOpen ? 0 : 4,
      borderBottomRightRadius: state.menuIsOpen ? 0 : 4,
    }
  },
  indicatorSeparator: (): object => ({}),
  option: (provided: any, state: any): object => ({
    ...provided,
    background: state.isSelected ? '#F2F2F2' : '#FFFFFF',
    cursor: 'pointer',
    borderBottom: '1px solid #D8D8D8',
    color: state.isDisabled ? '#D8D8D8' : '#000000',
    padding: '12px 10px',
    fontFamily: 'Inter',
    fontSize: '14px',
    '&:hover': {
      background: state.isDisabled ? '#FFFFFF' : '#F2F2F2',
    },
  }),
  menu: (provided: any, state: any): object => ({
    ...provided,
    borderRadius: 0,
    marginTop: '0',
    borderTop: 'none',
    cursor: 'pointer',
    boxShadow: '0 0px 0 2px rgba(0,0,0,0.10)',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
  }),
  menuList: (provided: any, state: any): object => ({
    ...provided,
    padding: 0,
    margin: 0,
    marginTop: 0,
    border: '1px solid #000000',
    borderTop: 'none',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
  }),
}

export default selectStyles
